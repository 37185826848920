<template>
  <div class="queues">
    <h1>Queues</h1>
    <div class="project-select">
    <div v-if="projects !== null">
      <div class="project-select-items" v-for="project, index in projects" :key="index">
        <span>{{project.name}}</span>
        <input type="checkbox" @click="handleSelectProject(project)" :checked="project.active"/>
      </div>
    </div>
  </div>
    <table id="queue-table">
      <thead>
        <tr>
          <th>Name</th>
          <th>State</th>
          <th>Project</th>
        </tr>
      </thead>
      <tbody>        
        <tr v-for="queue, index in queues" :key="index">
           <td>{{ queue.name}}</td>
           <td>{{ queue.state}}</td>
           <td>{{ queue.project }}</td>
        </tr>
        <tr v-if="queuesLoading">
          <td><font-awesome-icon :icon="['fas', 'spinner']" class="loading-component"/></td>
          <td><font-awesome-icon :icon="['fas', 'spinner']" class="loading-component"/></td>
          <td><font-awesome-icon :icon="['fas', 'spinner']" class="loading-component"/></td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'

export default {
  name: 'Queues',
  computed: {
    ...mapGetters({
      queues: "getQueues",
      queuesLoading: "queuesLoading",
      projects: "getProjects"
    })
  },
  methods: {
    ...mapActions(['loadQueues', 'filterQueues']),
    handleSelectProject(project){
      project.active = !project.active
      if(project.active){
        this.loadQueues()
      }
      else{
        this.filterQueues(project.name)
      }
    },
  },
  mounted () {
      this.loadQueues()
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.loading-component {
    top: 50%;
    left: 50%;
    width: 50px;
    height: 50px;
}
.project-select{
  margin:auto;
  text-align: center;
}
.project-select-items{
  display: inline-flex;
  justify-content: center;
  margin: 1rem;  
}

</style>
